<template>
  <div id="window2" class="iwindow" v-show="vIsDisplay" draggable="false"
    style="top: 50px; left: 50px; width:1000px; height:650px; max-height: 650px;">
    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <div style="float:left;">
        KBDeX Main
      </div>
      <div style="float:right;">
        <button class="iwindow-closebutton" v-on:click="closeWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="iwindow-body" style="overflow: scroll;">
      <button class="" v-on:click="openWWindow">word</button>
      <div id="windows" style="width: 100%; height: 600px;"></div>
    </div>
  </div>
  <div id="window1" class="iwindow" v-show="vWIsDisplay" draggable="false"
    style="top: 100px; left: 300px; height: 400px;">
    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <div style="float:left;">
        KBDeX WordSelecting
      </div>
      <div style="float:right;">
        <button class="iwindow-closebutton" v-on:click="closeWWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="iwindow-body aext">
      <div style="float: left;">
        Words:<button @click="searchKeyPressed">Set</button><br>
        <textarea v-model="vSelectedWords" rows="20"></textarea>
      </div>
      <div style="float: left; height: 100%;">
        Frequency:
        <button @click="frequencyKeyPressed">Update</button>
        <div id="freq_list" style="height: 90%; overflow-y: scroll;">
          <table>
            <thead>
              <tr>
                <th>word</th>
                <th>freq</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="word in this.vFreqList" v-bind:key="word.word">
                <td><a class="KFlink" @click="wordPressed(word.word)">{{ word.word }}</a></td>
                <td>{{ word.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */ //eslintのエラー制限
export default {
  name: "KBDeXVue",
  data() {
    return {
      vIsDisplay: false,
      vWIsDisplay: false,
      vSelectedWords: '',
      vFreqList: [],
      vSetting: null,
    };
  },
  props: {
    gKFdata: null
  },
  emits: ['openlink'],
  methods: {
    openWindow: async function () {
      var w = window.innerWidth;
      // document.getElementById('window1').style.left = `${w - 300}px`;
      // document.getElementById('window1').style.top = `70px`;
      // document.getElementById('window2').style.left = `200px`;
      // document.getElementById('window2').style.top = `270px`;
      this.vIsDisplay = true;
      this.vWIsDisplay = true;
      await this.frequencyKeyPressed();

      //load setting
      var query = {
        from: this.gKFdata.user._id,
        to: this.gKFdata.currentView._id,
        type: 'KBDeXSetting'
      };
      var settings = await kf6.getLinks(query);
      if (settings.length > 0) {
        this.vSelectedWords = '';
        this.vSetting = settings[0];
        for (var i = 0; i < this.vSetting.data.length; i++) {
          var word = this.vSetting.data[i];
          if (this.vSelectedWords.length > 0) {
            this.vSelectedWords += '\n';
          }
          this.vSelectedWords += word;
        }
      }
    },
    closeWindow: function () {
      this.clearHighlighting();
      this.vIsDisplay = false;
      this.vWIsDisplay = false;
    },
    closeWWindow: function () {
      //this.clearHighlighting();
      this.vWIsDisplay = false;
    },
    openWWindow: function () {
      this.vWIsDisplay = true;
    },
    wordPressed: async function (word) {
      if (this.vSelectedWords.indexOf(word) !== -1) {
        return;
      }
      if (this.vSelectedWords.length > 0) {
        this.vSelectedWords += `\n`;
      }
      this.vSelectedWords += word;
    },
    searchKeyPressed: async function (word) {
      var selectedWords = this.vSelectedWords.split('\n');

      //console.log(selectedWords);
      //const sheetValues = sheetJS.sheetValues; // JSON形式の発話データ
      //const selectedWords = sheetJS.selectedWords;
      var query = {
        type: 'Note',
        viewIds: [this.gKFdata.currentView._id],
        pagesize: 1000
      };
      var objects = await kf6.getObjects(query);
      var notes = [];

      var unitid_note_table = {};

      for (var i = 0; i < objects.length; i++) {
        var object = objects[i];
        var note = {};
        note.id = `${i + 1} `;
        note.text = object.text4search;
        note.author = this.gKFdata.authorsTable[object.authors[0]].lastName;
        note.time = `${i + 1} `;
        notes.push(note);
        unitid_note_table[note.id] = object;
      }

      const open = new Open(notes);
      // ネットワークの再構築
      var graphmodel = open.drawNetwork(selectedWords);
      this.gmodel = graphmodel;

      var self = this;
      var linktable = {};
      for (var i = 0; i < this.gKFdata.linksOnView.length; i++) {
        var link = this.gKFdata.linksOnView[i];
        linktable[link.to] = link;
      }

      graphmodel.selectionHandler = async function (units) {
        self.clearHighlighting();
        //console.log(units);
        if (Array.isArray(units)) {
          for (var i = 0; i < units.length; i++) {
            var unit = units[i];
            var note = unitid_note_table[unit.id];
            var link = linktable[note._id];
            link.isfound = true;
          }
        } else {
          var unit = units;
          var note = unitid_note_table[unit.id];
          var link = linktable[note._id];
          link.isfound = true;
        }
      };

      kbdex.unitDoubleClicked = function (n_unit) {
        //var id = unit.id;
        var note = unitid_note_table[n_unit.id];
        var link = linktable[note._id];

        //open!!
        self.$emit('openlink', link);
        //link.isfound = true;
        //console.log(id);
      };

      graphmodel.forwardToLast();

      if (this.vSetting) {
        this.vSetting.data = selectedWords;
        this.vSetting = await kf6.saveLink(this.vSetting);
      } else {//new        
        var link = {
          from: this.gKFdata.user._id,
          to: this.gKFdata.currentView._id,
          type: 'KBDeXSetting',
          data: selectedWords
        }
        this.vSetting = await kf6.createLink(link.from, link.to, link.type, link.data);
      }

    },
    // selectWord: async function (word) {
    //   this.clearHighlighting();

    //   if (typeof word === 'string') {
    //     this.vSearchKey = word;
    //   }

    //   var words = this.vSearchKey.split(' ');
    //   var query = {
    //     type: 'Note',
    //     viewIds: [this.gCurrentView._id],
    //     pagesize: 1000,
    //     words: words
    //   };
    //   var objects = await kf6.getObjects(query);

    //   var linktable = {};
    //   for (var i = 0; i < this.gLinks.length; i++) {
    //     var link = this.gLinks[i];
    //     linktable[link.to] = link;
    //   }

    //   for (var i = 0; i < objects.length; i++) {
    //     var object = objects[i];
    //     var link = linktable[object._id];
    //     if (link) {
    //       link.isfound = true;
    //     }
    //   }
    // },
    frequencyKeyPressed: async function () {
      var query = {
        type: 'Note',
        viewIds: [this.gKFdata.currentView._id],
        pagesize: 1000
      };
      var objects = await kf6.getObjects(query);
      var list = kftext.createWords(objects, 100);
      this.vFreqList = list;
    },
    clearHighlighting: function () {
      for (var i = 0; i < this.gKFdata.linksOnView.length; i++) {
        var link = this.gKFdata.linksOnView[i];
        link.isfound = false;
      }
    },
    mousedown: function (e) {
      aextkf.windowmover(e, this.windowMovedHandler);
    },
    windowMovedHandler: function () {
      if (this.gmodel) {
        this.gmodel.locationChanged();
      }
    }
  }
};
</script>

<style scoped></style>