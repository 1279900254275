<template>
  <div class="iwindow note-style" v-show="isDisplay">

    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <!-- <div class="iwindow-titlebar" :ref="search" @mousedown="mousedown($event)"> -->
      <div style="float: left;">
        Note
      </div>
      <div style="float: right;">
        <button class="iwindow-closebutton" v-on:click="closeWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <!-- <div class="iwindow-body"> -->

    <!-- 新規ノート作成時に表示される -->
    <div v-show="editNotePage">
      <div class="note-main" v-bind:class="{ 'note-main-padding': buildsonPage }">
        <div v-if="buildsonPage" class="note-buildson">
          <span>buildsonOrigin:{{ buildsonOrigin.title }}</span>
          <select v-if="fromChat" name="buildsonOrigin" id="buildsonOrigin" @change="changeBuildsonOrigin">
            <option disabled selected>buildson先を変更する</option>
            <option v-for="option in options" v-bind:value="option.to" v-bind:key="option">
              {{ option._to.title }}
            </option>
            <option value="createNote">新規ノート</option>
          </select>
          <button @click="this.buildsonOrigin.display = !this.buildsonOrigin.display
            ">内容を表示する</button>
          <div class="buildson-origin-contents" v-show="buildsonOrigin.display === true">
            <div v-html="buildsonOrigin.body"></div>
          </div>
          <hr>
        </div>
        <!-- buildsonボタンが押されたら -->
        <div class="note-title">
          Title:<input type="text" v-model="gObject.title" size="40" />
        </div>
        <div>
          <editor :api-key="apikey" :init="init" v-model="gObject.data.body" />
        </div>
        <!-- footer -->
        <div class="note-footer">
          <div class="note-permission">
            公開範囲
            <input name="permissions" type="radio" v-model="gObject.permission" value="private" /><label
              for="private">private</label>
            <input name="permissions" type="radio" v-model="gObject.permission" value="protected" /><label
              for="protected">protected</label>
            <input name="permissions" type="radio" v-model="gObject.permission" value="public" /><label
              for="public">public</label>
          </div>
          <div>
            <button v-on:click="submit">貢献する</button>
          </div>
        </div>
        <!-- footer -->
      </div>
    </div>
    <!-- 新規ノート作成時に表示される -->

    <!-- ノートを読むときに表示される -->
    <div class="note-main" v-show="readNotePage">
      <div class="note-title">
        <div>{{ gObject.title }}</div>
      </div>
      <hr />
      <div class="note-contents">
        <div v-html="gObject.data.body"></div>
        <!-- Attachmentだった場合ダウンロードボタンを表示する -->
        <button v-show="downloadButton" @click.prevent="downloadItem(gObject)">
          Open
        </button>
      </div>
      <div class="note-footer">
        <div class="note-author" v-if="!hiddenName">
          {{ author }}<br />{{ time }}
        </div>
        <div>
          <button v-if="editPermission" @click="openWindow('edit')">
            edit
          </button>
          <button @click="createNote(gObject)">Buildson</button>
        </div>
      </div>
    </div>
    <!-- ノートを読むときに表示される -->
  </div>
</template>

<script>
/* eslint-disable */ //eslintのエラー制限
// @ is an alias to /src
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "Note",
  data() {
    return {
      init: {
        language: 'ja',
        height: 400,
      },
      apikey: "2k1u2pltqbfwto0wrrk8r0k6a6s0a9rntmiadyksrq3cii8b",
      isDisplay: false,
      editNotePage: false,
      readNotePage: false,
      editPermission: false,
      buildsonPage: false,
      downloadButton: false,
      gObject: {
        title: "",
        data: { body: "" },
        permisson: "protected",
        url: "",
      },
      windowX: 500,
      windowY: 50,
      author: "",
      time: "",
      buildsonOrigin: { title: "", body: "", permission: "protected", display: false },
      options: [],
      currentOption: "",
      fromChat: false,
      w: 700,
      h: 400
    };
  },
  components: {
    editor: Editor,
  },
  props: {
    gCurrentView: null,
    objectId: String,
    note: null,
    hiddenName: null,
    gLinks: null,
  },
  methods: {
    openWindow: function (event) {
      if (this.fromChat) {
        var time = new Date(this.timeOfDropTarget).getTime()
        this.options = this.gLinks
        this.options = this.options.filter((e) => {
          return e._to.time[1] < time
        })
      }
      if (event === "edit") {
        this.editNotePage = true;
        this.readNotePage = false;
      } else if (event === "read") {
        this.editNotePage = false;
        this.readNotePage = true;
      }
      if (this.buildsonOrigin.title) {
        this.buildsonPage = true
      }
      this.isDisplay = true;
    },
    closeWindow: function () {
      //ノートを閉じるボタンが押されたら
      // if (this.editNotePage) {
      //   var confirmation = window.confirm("You have unsaved changes!\nChanges will be lost if you do not contribute them!\nAre you sure to leave? (You'll loose all the changes!)");
      //   if (!confirmation) {
      //     return;
      //   }
      // }
      this.isDisplay = false;
      this.editNotePage = false;
      this.readNotePage = false;
      this.buildsonPage = false;
      this.buildsonOrigin.display = false;
      this.gObject.data.body = ""
      this.fromChat = false;

    },
    submit: async function () {
      /* ノートの保存、変更 */
      if (!this.gObject.title) {
        //ノートのタイトルがない時はアラートを出す
        alert("タイトルがありません");
        return;
      }
      this.gObject.data.aext = true;
      this.gObject.status = "active";
      await kf6.modifyObject(this.gObject);
      alert("Saved");
      this.$emit("parent-event", this.gObject);
      this.closeWindow()
    },
    readNote: async function () {
      var note = this.note;
      //ノートが押されたら
      this.gObject = await kf6.getObject(this.objectId);
      await kf6.createReadMark(this.objectId);
      this.author = note._to.author.firstName + note._to.author.lastName;
      this.time = note._to.time[0];
      if (note._to.type === "Attachment") {
        this.downloadButton = true;
      }
      if (note._to.author._id === kf6.authorId) {
        //自分が作成したノートの場合editボタンを作成する
        this.editPermission = true;
      }
      this.openWindow("read")
    },
    downloadItem: function () {//Noteの内容がAttachmentだった場合、新しくウィンドウを開く
      var servername = sessionStorage.getItem("kfp.server");
      var url = servername.slice(0, -1) + this.gObject.data.url;
      window.open(url);
    },
    createNote: async function (to, from) {
      /* 新規ノートを作る */
      this.gObject = await kf6.createNote(this.gCurrentView, to, window);
      if (to) {
        this.buildsonOrigin.title = to.title;
        this.buildsonOrigin.body = to.data.body;
      }
      if (from) {
        this.gObject.data.body = from.data.body
        this.gObject.title = from.title
        this.gObject.permission = "protected"
      }
      this.openWindow("edit")
    },
    changeBuildsonOrigin: async function (e) {
      if (e.target.value === "createNote") {
        this.createNote(this.gObject)
        this.buildsonPage = false
        return
      }
      var to = await kf6.getObject(e.target.value)
      this.createNote(to, this.gObject)
    },
    mousedown: aextkf.windowmover
  }
};
</script>

<style scoped>
.note-style {
  /* background-color: #ffffff; */
  /* border: 1px solid #2c3e50; */
  /* position: absolute; */
  /* border-radius: 10px; */
}

.note-title,
.note-title input {
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* margin: 0 10px 0 0; */
}

.note-style:active {
  border: 1px solid #2c3e50;
}

.note-buildson {
  margin: 10px;
}

.note-main {
  width: 700px;
  height: 405px;
  position: relative;
  overflow: hidden;
  resize: both;
  padding: 10px 0px 100px 0px;
  min-width: 400px;
  margin: 0 10px 10px;
}

.note-main-padding {
  padding: 0 0 155px 0 !important;
}

.note-contents {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
}

.note-footer {
  display: flex;
  min-height: 30px;
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 10px 0 5px 0;
}

.note-footer div {
  width: fit-content;
  margin: 0 10px;
}

.note-footer button {
  cursor: pointer;
}

.note-author {
  font-size: x-small;

}

.buildson-origin-contents {
  height: 100px;
  overflow-y: scroll;
  resize: vertical;
}

/* select {
  width: 100px;
} */
</style>

