<template>
  <div id="search_window" class="iwindow aext" v-show="vIsDisplay" draggable="false">
    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <!-- <div class="iwindow-titlebar" :ref="search" @mousedown="mousedown($event)"> -->
      <div style="float:left;">
        ChaCo
      </div>
      <div style="float:right;">
        <button class="iwindow-closebutton" v-on:click="closeWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="iwindow-body">
      <div class="chat-main">
        <!-- context menu -->
        <vue-simple-context-menu-chat :elementId="'context-menu-chat/' + root.to" :options="contextmenu"
          :ref="'vueSimpleContextMenu' + root.to" @option-clicked="contextMenuPressed"
          :id="'context-menu' + root.to"></vue-simple-context-menu-chat>
        <!-- context menu -->
        <div class="chat-comments" ref="chatLines" @mousedown="(linkingStyle = null)" @drop.stop
          @click="clearDragTargets()">
          <div v-for="(comment) in comments" v-bind:key="comment._id">
            <div v-bind:class="{
              right: comment.authors[0] == me,
            }">
              <div class="chat-comment" v-bind:class="{
                'chat-comment-me': comment.authors[0] == me,
                linking: linkingStyle === comment._id,
                selected: comment.selected,
              }" @click.stop="commentPressed($event, comment)" draggable="true" @dragstart="dragstart($event, comment)"
                @contextmenu.passive="showContextMenu($event, comment)">
                <div v-if="comment.data.attachmentId" class="re">
                  <img v-if="comment.data.attachmentId" src="../../manual_assets/kf4images/icon-attachment.gif"
                    alt="uploadAttachment" /><span v-html="comment.data.attachmentTitle"></span>

                </div>
                <div v-if="comment.data.replyId" class="re">
                  <span v-html="comment.data.replyContents"></span>
                </div>

                <div v-html="comment.data.body" class="chat-contents" :ref="comment._id"></div>
                <span class="chat-author" v-if="!hiddenName">{{
                  comment.author.firstName + comment.author.lastName
                }}</span>
                <br />
                <span>{{ comment.time }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="preview-item">
          <img v-if="uploadedImage" class="preview-item-file" :src="uploadedImage" alt="" />
          <button v-if="uploadedImage" @click="toolbarPressed('cansel')">×</button>
          <div v-if="preview" style="padding:5px">
            <div v-if="preview._to.title">{{ preview._to.title }}</div>
            <button @click="toolbarPressed('cansel')">×</button>
          </div>
          <div v-if="reply">
            <div v-html="reply.data.body"></div>
            <button @click="toolbarPressed('cansel')">×</button>
          </div>

        </div>
      </div>

      <div class="chat-input">
        <div class="chat-tool">
          <button @click="toolbarPressed('attachment')">
            <img src="../../manual_assets/kf6images/03-toolbar-attachment.png" alt="uploadAttachment" />
          </button>

          <input type="file" @change="selectedFile" ref="file" />

          <button @click="toolbarPressed('code')">&lt;&gt;</button>
          <button @click="toolbarPressed('on/off')" v-bind:class="{ on: switchButton === 'on' }"
            style="font-size: 11px;">自動更新:{{ switchButton }}</button>
        </div>
        <div>
          <editor :api-key="apikey" :init="{
            language: 'ja',
            height: 100,
            toolbar: [],
            menubar: false,
            accessibility_warnings: false,
            auto_focus: 'editor'
          }" v-model="gObject.data.body" :ref="'chat-tinymce/' + root.to" />
        </div>
        <!-- footer -->
        <div class="chat-footer"><button @click="submit">送信</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ //eslintのエラー制限
import Editor from "@tinymce/tinymce-vue";
/* vue simple context menu */
import "vue-3-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-3-simple-context-menu";
import { throwStatement } from "@babel/types";
export default {
  name: "Chat",
  data() {
    return {
      comments: [],
      vIsDisplay: false,
      gObject: {
        title: "",
        data: { body: "" },
        permisson: "protected",
      },
      me: "",
      file: "",
      uploadedImage: "",
      preview: "",
      reply: "",
      servername: "",
      timer: null,
      paused: true,
      switchButton: "off",
      apikey: "2k1u2pltqbfwto0wrrk8r0k6a6s0a9rntmiadyksrq3cii8b",
      linkingStyle: null, //チャットと紐付けされているものを光らせるcss
      gLinks: [],
      dragTargets: [],
      x: 30,
      y: 50,
      contextmenu: [],

    };
  },
  props: {
    gCurrentView: null,
    hiddenName: null,
    root: null,
    gAuthorsTable: null,
  },
  components: {
    editor: Editor,
    VueSimpleContextMenuChat: VueSimpleContextMenu,
  },
  created() {
    this.servername = sessionStorage.getItem("kfp.server");
    this.servername = this.servername.slice(0, -1);
  },
  mounted: function () {
    this.$refs["chat-tinymce/" + this.root.to].$el.focus()
  },
  methods: {
    mousedown: aextkf.windowmover,
    openWindow: async function (object) {
      if (object) {
        this.preview = object
      }
      // this.target = this.$refs.chatLines;
      this.vIsDisplay = true;
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },

    closeWindow: function () {
      //ノートを閉じるボタンが押されたら
      this.vIsDisplay = false;
      this.gObject.data.body = "";
      this.$refs.file.value = null;
      this.uploadedImage = null;
      this.preview = null;
      this.paused = true;
      this.switchButton = "off"
      clearInterval(this.timer);
      this.gLinks = []
      this.comments = []
    },
    toolbarPressed: function (tool) {
      //chat欄のツールバー
      if (tool === "attachment") {
        this.$refs.file.click();
      } else if (tool === "cansel") {
        this.$refs.file.value = "";
        this.files = "";
        this.gObject.data.body = "";
        this.uploadedImage = "";
        this.preview = "";
        this.reply = ""

      } else if (tool === "code") {
        this.gObject.data.body = `<pre><code>ここにコードを追加します</code></pre>`;
      } else if (tool === "on/off") {
        if (this.paused === false) {
          this.paused = true
          this.switchButton = "off"
          clearInterval(this.timer);
        } else {
          this.startTimer()
          this.switchButton = "on"
        }
      }
    },

    submit: async function () {
      if (this.gObject.data.body === "") {
        //ノートのタイトルがない時はアラートを出す
        alert("contentsがありません");
        return;
      } else {
        var data = this.gObject.data
        if (this.files) {
          //チャットから画像をアップロードした場合
          var attachment = await kf6.uploadFile(this.files[0], window, this.gCurrentView._id);
          this.files = "";
          data.attachmentId = attachment._id
          data.attachmentTitle = attachment.title
          this.$refs.file.value = "";
          this.preview = "";
          this.uploadedImage = "";
        }
        else if (this.preview) {
          //ノートを参照してチャットを送信した場合
          data.attachmentId = this.preview.to
          data.attachmentTitle = this.preview._to.title
        } else if (this.reply) {
          //チャットにリプライした場合
          data.replyId = this.reply._id
          data.replyContents = this.reply.data.body
          this.reply = ""
        }

        if (this.gObject._id) {//コメントの編集だった場合
          await kf6.modifyObject(this.gObject);
        } else {//新規コメントだった場合
          var note = await kf6.getObject(this.root.to)
          await kf6.createChat(this.gCurrentView, note, data);
          this.chatReload()
        }
        //テキストエリアをからにする
        this.gObject = {
          data: { body: "" },
        }
      }
    },

    selectedFile: function (e) {
      // 選択された File の情報を保存しておく
      this.files = e.target.files;
      this.gObject.data.body = `[${this.files[0].name}]を添付`;
      this.uploadedImage = URL.createObjectURL(this.files[0]);
    },

    commentPressed: function (event, comment) {
      if (event.shiftKey) {
        if (comment.selected === true) {
          comment.selected = !comment.selected
          var index = this.dragTargets.indexOf(comment._id);
          this.dragTargets.splice(index, 1);
        } else {
          comment.selected = !comment.selected
          this.dragTargets.push(comment)

        }
        return
      } else {
        this.clearDragTargets()
      }
      if (comment) {
        if (comment.data.attachmentId) {
          //紐付けされているobjがあれば光る
          this.$emit("chatLinking", comment.data.attachmentId);
        } else if (comment.data.replyId) {
          //紐付けされているobjがあれば光る
          this.linkingStyle = comment.data.replyId
        }
      }

    },
    clearDragTargets: function () {
      if (this.dragTargets) {
        for (var i = 0; i <= this.dragTargets.length - 1; i++) {
          var each = this.dragTargets[i]
          var result = this.comments.find((e) => {
            return e._id === each._id
          })
          result.selected = !result.selected
        }
        this.dragTargets = []
      }
    },
    chatReload: async function () {//チャットのreloadボタンを押したときに発火する
      this.me = kf6.authorId;
      var links = await kf6.getLinksTo(this.root.to)
      links = links.filter((each) => {
        return each.type === "replies"
      })
      if (this.gLinks.length === links.length || this.comments.length === links.length) {//更新したときにコメント数が変わっていなかったら以下の処理を行わない
        return;
      } else {
        for (var i = 0; i <= links.length - 1; i++) {
          var result = this.gLinks.find((each) => { return each.from === links[i].from }) //新規コメントがあるかどうか
          if (!result) {
            var comment = await kf6.getObject(links[i].from)
            comment["author"] = this.getAuthor(comment.authors[0])
            comment["time"] = await this.getTimeString(comment.created); //時間を取得する
            this.comments.push(comment)

          }
        }
        this.gLinks = links
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    getAuthor: function (authorId) {
      var eachAuthor = this.gAuthorsTable[authorId]
      return {
        firstName: eachAuthor.firstName,
        lastName: eachAuthor.lastName
      }
    },
    scrollToBottom: function () {
      //チャットの1番下までスクロール
      var scrollTargetet = this.$refs.chatLines;
      scrollTargetet.scrollTop = scrollTargetet.scrollHeight;
    },
    deletePressed: async function (comment) {
      var link = await kf6.getLinksFrom(comment._id)
      link = link[0]
      if (link) {
        var confirmation = window.confirm(
          "Are you sure to delete the selected " + 1 + " object(s)?"
        );
        if (!confirmation) {
          return;
        } else {
          await kf6.deleteLink(link)
          var index = this.comments.findIndex((e) => {
            return e._id === comment._id
          })
          this.comments.splice(index, 1)
          index = this.gLinks.findIndex((e) => {
            return e._id === link._id
          })
          this.gLinks.splice(index, 1)
        }
      }
    },
    dragstart: function (event, data) {
      var text = data.data.body.replace(/(<([^>]+)>)/gi, '');
      var data = text
      if (this.dragTargets.length !== 0) {
        data = []
        for (var i = 0; i <= this.dragTargets.length - 1; i++) {
          //著者を追加したいときはここにかく
          text = this.dragTargets[i].data.body.replace(/(<([^>]+)>)/gi, '');
          data.push(text)
        }
        this.clearDragTargets()
        data = JSON.stringify(data)
      }
      event.dataTransfer.setData('text', data)
    },
    startTimer: function () {
      this.paused = false
      this.timer = setInterval(() => {
        this.chatReload()
      }, 2000);

    },
    getTimeString: function (time) {
      //時間を取得
      var d = new Date(time);
      return d.toLocaleString();
    },
    stringToHTML: function (str) {
      var dom = document.createElement('div');
      dom.innerHTML = str;
      return dom;
    },
    showContextMenu: function (event, item) {
      this.contextmenu = [
        { name: "Copy to Clipboard", slug: "CopyToClipboard" },
        { name: "reply", slug: "reply" },
      ]
      if (item.authors[0] === kf6.authorId) {
        this.contextmenu.push({ name: "Delete", slug: "delete" }, { name: "edit", slug: "edit" })

      }
      this.$refs['vueSimpleContextMenu' + this.root.to].showMenu(event, item);
      var menu = document.getElementById('context-menu' + this.root.to).getElementsByTagName('ul')[0]
      menu.style.left = event.clientX - this.x + "px"
      menu.style.top = event.clientY - this.y + "px"
    },
    contextMenuPressed: function (event) {
      var slug = event.option.slug;
      var comment = event.item;
      if (slug === "delete") {
        this.deletePressed(comment);
      } else if (slug === "CopyToClipboard") {
        var copyTarget = this.$refs[comment._id][0]
        var text = copyTarget.textContent
        navigator.clipboard.writeText(text).then(() => {
          window.alert("clipboard successfully set ")
        }, () => {
          window.alert('clipboard write failed ')
        });
      } else if (slug === "reply") {
        this.reply = comment
      } else if (slug === "edit") {
        this.gObject = comment
      }
    },
  }
};
</script>
<style scoped>
.chat-wrap {
  background-color: #ffffff;
  border: 1px solid #2c3e50;
  z-index: 1px;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 180px;
}

.chat-wrap span {
  font-weight: bold;
}

.chat-main {
  position: relative;
  width: 100%;
  min-width: 176px;
}

.close-button {
  position: absolute;
  right: -5px;
  top: -5px;
  text-align: center;
  z-index: 5;
}

.chat-header {
  width: 100%;
  z-index: 2;
  background-color: #f8f8f8;
  border-bottom: 1px solid #2c3e50;
  border-radius: 10px 10px 0 0;
  cursor: move;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-comments {
  position: relative;
  width: 100%;
  height: 450px;
  overflow-y: scroll;
  z-index: 0;
  resize: vertical;
  font-size: 14px;
}

.chat-comments img {
  width: 13px;
  float: left;
}


.right {
  text-align: right;
}

.chat-comment,
.chat-comment-me {
  max-width: 400px;
  padding: 10px;
  margin: 2px 10px;
  border-radius: 10px;
  position: relative;
  /* z-index:1; */
  display: inline-block;
  line-height: 0.5em;
  overflow-wrap: anywhere;
}

.chat-comment {
  background-color: #dddddd;
}

.chat-comment-me {
  background-color: #6ac782;
}

.chat-comment span,
.chat-comment-me span {
  font-size: x-small;
  clear: both;
}

.chat-comment:hover {
  background-color: #efefef;
}

.chat-comment-me:hover {
  background-color: #99ecae;
}

.selected {
  border: 1px solid red;
}

.chat-contents {
  margin: 10px 0;
  line-height: 1;
  text-align: left;
}

.chat-tool {
  display: inline-block;
}

.chat-tool img {
  width: 15px;
}

.chat-input {
  padding: 10px 0 5px 0;
  height: 100%;
  width: auto;
  border-top: 1px solid;
}

.chat-input input {
  /* margin: 5px; */
  display: none;
}

.chat-input textarea {
  width: 90%;
  margin: 5px 5%;
  display: inline-block;
  resize: none;
}

.chat-input button {
  margin: 0 5px;
  font-size: 15px;
  vertical-align: top;
}

.preview-item {
  /* border-top: 1px solid #2c3e50; */
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: rgba(170, 170, 170, 0.5);
}

.preview-item img,
.preview-attachment {
  width: 200px;
}

.preview-item button {
  position: absolute;
  right: 0px;
  top: -10px;
}

.re {
  text-align: left;
  font-size: 10px;
  border-bottom: 0.5px solid black;
  overflow: scroll;
  height: 30px;
}

.re span {
  font-weight: normal;
  line-height: normal;
}

.re p {
  margin: 0
}

/* 自動更新がONになっているときにcssを適用する */
.on {
  background-color: #6ac782;
}

/* チャットを光らせる */
.linking {
  border: 1px solid #e5ff00;
  box-shadow: 0 3px 30px 0 rgba(255, 191, 0, 0.579);
}

.chat-footer {
  position: relative;
  width: 400px;
  margin: 0 5px 5px;
  padding: 0 0 5px;
  resize: both;
  overflow: hidden;
  min-height: 25px;
  min-width: 430px;
}

.chat-footer button {
  position: absolute;
  bottom: 0;
}
</style>