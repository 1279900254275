<template>
  <div id="chot_window" class="iwindow aext" v-show="vIsDisplay" draggable="false"
    style="left: 150px; width: 800px; height: 600px;">
    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <div style="float:left;">
        Chot GPT
      </div>
      <div style="float:right;">
        <button class="iwindow-closebutton" v-on:click="closeWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="iwindow-body">
      <div id="chot_wrapper">
        <aside id="sidebar" class="chatcreat" hidden>
          <span class="chat-name"></span>
          <input id="chat-title" type="textfield">
          <input type="button" onclick="chot.createNewSessionPressed()" value="new" id="newchat-button">
          <div id="history-wrap">
            <p id="hitory-list"></p>
          </div>
        </aside>
        <div class="chat-body">
          <div>
            <select id="gptversion" onchange="chot.gptversionChanged()">
              <option value="gpt-3.5-turbo">GPT3.5 turbo</option>
              <option value="gpt-4">GPT4</option>
            </select>
          </div>
          <div id="chot_field">
            <ul id="chat-ul"></ul>
            <p id="loading"></p>
            <p id="continue"><input type="checkbox" id="continue_btn" onclick="chot.clickContinue();">続きを生成する</p>
            <div class="evaluation">
              <!-- <img src="./img/good.png" class="good_img" alt="" onclick="chot.getEvaluation('good')"> -->
              <!-- <img src="./img/bad.png" class="bad_img" alt="" onclick="chot.getEvaluation('bad')"> -->
            </div>
          </div>
          <div id="input-field">
            <textarea id="chat-input" cols="50" rows="5"></textarea>
            <div class="chot-btn-wrap">
              <input type="button" value="Send" id="chat-button" onclick="chot.chatbtn()">
            </div>
            <!-- <div class="chot-btn-wrap">
              <button @click="sumbtn()">sum</button>
            </div>  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

/* eslint-disable */ //eslintのエラー制限
export default {
  name: "Chot",
  data() {
    return {
      vIsDisplay: false
    };
  },
  props: {
    gKFdata: null
  },
  components: {
  },
  created() {
  },
  mounted() {
    chot.init();
  },
  methods: {
    mousedown: aextkf.windowmover,
    async openWindow(chotSessionId) {
      this.vIsDisplay = true;

      chot.view = this.gKFdata.currentView;
      var context = this.gKFdata.context;
      if (context.data && context.data.plugins && context.data.plugins.chotGPT) {
        chot.API_KEY = context.data.plugins.chotGPT.APIkey;
      }

      if (chotSessionId) {
        document.getElementById('sidebar').hidden = true;
        await chot.setSession(chotSessionId);
      } else {
        document.getElementById('sidebar').hidden = false;
        await chot.refreshSessionList();
      }
    },

    closeWindow() {
      this.vIsDisplay = false;
    },

    async sumbtn() {
      var kftext = '';
      var objects = await kf6.getObjects({ views: [this.gKFdata.currentView._id] });
      for (var i = 0; i < objects.length; i++) {
        var object = objects[i];
        kftext += object.text4search;
      }
      chot.chatbtn('次の文章を要約して', kftext);
    }
  }
};
</script>
<style scoped></style>