<template>
  <div id="search_window" class="iwindow aext" v-show="vIsDisplay" draggable="false">
    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <div style="float:left;">
        Upload Attachment
      </div>
      <div style="float:right;">
        <button class="iwindow-closebutton" v-on:click="closeWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="iwindow-body">
      <div class="Upload-upload-area">
        <label for=""><input type="file" @change="getFile" ref="file" /></label>
        <br>
        <div class="Upload-drop-area" @dragenter.prevent @dragleave.prevent @dragover.prevent @drop.prevent="dropFile">
          drop Area
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ //eslintのエラー制限
export default {
  name: "uploadAttachment",
  data() {
    return {
      vIsDisplay: false,
      uploadFile: null,
    };
  },
  props: { gCurrentView: null },
  methods: {
    mousedown: aextkf.windowmover,
    openWindow: async function () {
      this.vIsDisplay = true;
    },
    closeWindow: function () {
      //ノートを閉じるボタンが押されたら
      this.vIsDisplay = false;
      this.$refs.file.value = null;
    },
    dropFile: async function (e) {
      let files = e.dataTransfer.files;
      this.selectedFile(files)
    },
    getFile: function (e) {
      let files = e.target.files;
      this.selectedFile(files)
    },
    selectedFile: async function (files) {
      // 選択された File の情報を保存しておく
      for (var i = 0; i <= files.length - 1; i++) {
        var file = files[i]
        await kf6.uploadFile(file, window, this.gCurrentView._id);
      }

      this.closeWindow()
    },
  },
};
</script>
<style scoped>
.Upload-upload-area {
  background-color: white;
  /* border: 1px solid #dddddd; */
  /* position: absolute; */
  top: 50px;
  left: 30px;
  border-radius: 10px;
  padding: 30px;
  justify-content: center;
  align-items: center;
}

.Upload-drop-area {
  border: 1px dashed #41b983;
  border-radius: 10px;
  width: 250px;
  height: 100px;
  margin-top: 10px;
}
</style>