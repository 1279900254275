<template>
  <div class="community">
    <h1>Community</h1>
    <table>
      <tr v-for="registration in vRegistrations" v-bind:key="registration.id">
        <td>{{ registration._community.title }}</td>
        <td>
          <button @click="communityPressed(registration.communityId)">
            コミュニティに入る
          </button>
        </td>
      </tr>
    </table>
    <div class="add-registration">コミュニティに参加する
      <table>
        <tr>
          <td>コミュニティ:
          </td>
          <td>
            <select v-model="selected">
              <option disabled selected>コミュニティを選択する</option>
              <option v-for="community in vCommunities" v-bind:value="community" :key="community">
                {{ community.title }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>コミュニティ登録キー：</td>
          <td><input type="text" v-model="selectedKey" /></td>
        </tr>
      </table>
      <button @click="addRegistration()">参加する</button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "Community",
  data() {
    return {
      vRegistrations: [],
      vRegistrationTable: {},
      vCommunities: [],
      selected: "",
      selectedKey: "",
    };
  },
  props: {
    gKFdata: null
  },
  async mounted() {//ページが読み込まれたとき
    kf6.token = window.sessionStorage.getItem("token");
    kf6.baseURL = window.sessionStorage.getItem("kfp.server");

    this.gKFdata.communityName = '';
    this.gKFdata.viewName = '';
    this.gKFdata.userName = '';

    this.vRegistrations = await kf6.getCommunities(); //コミュニティを取得
    for (var i = 0; i < this.vRegistrations.length; i++) {
      var reg = this.vRegistrations[i];
      this.vRegistrationTable[reg.communityId] = reg;
    }
    this.vCommunities = await kf6.getAllCommunities();
  },

  methods: {
    communityPressed: async function (communityId) {

      var authorId = this.vRegistrationTable[communityId]._id;

      window.sessionStorage.setItem("communityId", communityId);
      window.sessionStorage.setItem("authorId", authorId);

      kf6.setCommunity(communityId);
      kf6.setAuthorId(authorId);

      var views = await kf6.getViews();
      var viewId = views[0]._id;
      this.$router.push({ name: "view", params: { id: viewId } });
    },
    addRegistration: async function () {
      if (!this.selected) {
        window.alert('Community must be selected');
        return;
      }
      if (this.selected.registrationKey === this.selectedKey) {
        var author = {};
        this.me = await kf6.getMe()
        author.userId = this.me._id;
        author.communityId = this.selected._id;
        author.registrationKey = this.selectedKey;
        await kf6.modifyMeAsAuthor(author)
        this.$router.push({ name: "community" });
        window.location.reload();
      } else {
        window.alert('Error: RegistrationKey does not match.')
      }
      return

    }
  },

};
</script>
<style scoped>
.community {
  padding: 10px;
}

.add-registration {
  margin: 50px 0;
}
</style>