import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue';
import Community from '../views/Community.vue';
import View from '../views/View.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    props: true
  },
  {
    path: '/community',
    name: 'community',
    component: Community,
    props: true
  },
  {
    path: '/view:id',
    name: 'view',
    component: View,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
