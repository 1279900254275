<!-- <template>
  <div class="note-style" v-show="isDisplay" :style="{ left: `${windowX}px`, top: `${windowY}px` }">
    <button class="close-button" v-on:click="closeWindow">
      <span>×</span>
    </button>
    <div class="note-header" @mousedown="mousedown($event)" @mousemove="mousemove($event)" @mouseup="mouseup($event)"
      @mouseleave="mouseleave($event)">
    </div>
   新規ノート作成時に表示される
    <div class="note-main">
      <div id="container" style="width:100%;height:100vh"></div>
      <iframe :src="url" frameborder="0" id="iframe"></iframe>
    </div>
    <div class="footer">
      <button @click="contribute" ref="contribute">貢献する</button>
    </div>
  </div>
</template> -->

<template>
  <div class="note-style iwindow aext" v-show="vIsDisplay" draggable="false" style="height: 300px;">
    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <!-- <div class="iwindow-titlebar" :ref="search" @mousedown="mousedown($event)"> -->
      <div style="float:left;">
        Drawing Tool
      </div>
      <div style="float:right;">
        <button class="iwindow-closebutton" v-on:click="closeWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="iwindow-body note-main">
      <div>
        <button v-on:click="submit">貢献する</button>
      </div>
      <!-- <div id="container" style="width:100%;height:100vh"></div> -->
      <iframe :src="url" frameborder="0" id="iframe"></iframe>
    </div>
    <div class="note-footer">
      <!-- <div class="note-permission">
        公開範囲
        <input name="permissions" type="radio" v-model="gObject.permission" value="private" /><label
          for="private">private</label>
        <input name="permissions" type="radio" v-model="gObject.permission" value="protected" /><label
          for="protected">protected</label>
        <input name="permissions" type="radio" v-model="gObject.permission" value="public" /><label
          for="public">public</label>
      </div> -->
      <div>
        <button v-on:click="contribute">貢献する</button>
      </div>
    </div>
  </div>
</template>

<script type="module">
/* eslint-disable */
// @ is an alias to /src
export default {
  name: "iframetool",
  data() {
    return {
      vIsDisplay: false,
      //isDisplay: false,
      // windowX: 60,
      // windowY: 60,
      url: "",
      htmlContent: "",
    };
  },
  methods: {
    openWindow: function (tool) {
      this.vIsDisplay = true;
      if (tool === "kakeru") {
        this.url = `https://kakeru.app`;
      }
    },
    closeWindow: function () {
      this.vIsDisplay = false;
    },
    contribute: async function () {
      var iframe = document.getElementById("iframe")
    },
    mousedown: aextkf.windowmover
  },
};
</script>
<style scoped>
.note-style {
  background-color: #ffffff;
  border: 1px solid #2c3e50;
  position: absolute;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.note-style span {
  font-weight: bold;
}

.close-button {
  position: absolute;
  right: -5px;
  top: -5px;
  text-align: center;
}

.note-header {
  width: 100%;
  height: 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #2c3e50;
  border-radius: 10px 10px 0 0;
  cursor: move;
}

.note-main {
  width: 100%;
  margin: 3px 10px;
  overflow-wrap: break-word;
}

.note-main iframe {
  display: inline-block;
  width: 900px;
  height: 500px;
}

/* footer */
.footer {
  text-align: right;
}
</style>