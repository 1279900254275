<template>
  <header>
    <div class="KFCommunityTitle" @click="toWelcome">{{ gKFdata.communityName }}</div>
    <div class="KFViewTitle">{{ gKFdata.viewName }}</div>
    <div class="KFUserName" @click="openSetting">
      {{ gKFdata.userName }}
      <span></span>
      <span class="LogoutButton" @click="returnHome">logout</span>
    </div>
  </header>
  <div class="main-style">
    <router-view :gKFdata="gKFdata"></router-view>
  </div>
</template>

<script>
/* eslint-disable */ //eslintのエラー制限

export default {
  name: "app",
  data() {
    return {
      gKFdata: {
        communityName: 'communityName',
        viewName: 'viewname',
        userName: 'username'
      }
    }
  },
  methods: {
    returnHome: function () {
      window.sessionStorage.clear();
      this.$router.push({ name: "login", });
    },
    toWelcome: async function () {
      var views = await kf6.getViews();
      var viewId = views[0]._id;
      this.$router.push({ name: "view", params: { id: viewId } });
    },
    openSetting: function () {
      if (this.gKFdata.openSetting) {
        this.gKFdata.openSetting();
      }
    }
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1 {
  margin: 0;
}

body {
  margin: 0;
}

/* code css */
pre {
  margin: 1em 0;
  /* ブロック前後の余白 */
  padding: 1em;
  /* ブロック内の余白 */
  border-radius: 5px;
  /* 角丸 */
  background: #25292f;
  /* 背景色 */
  color: #fff;
  /* 文字色 */
  white-space: pre-wrap;
  /* はみ出たときに折り返す */
  text-align: left;
  line-height: 1em;

  padding-left: 1em;
  text-indent: -1em;
}

header {
  width: 100%;
  background-color: #dddddd;
  /* border-bottom: 1.5px solid #ffffff; */
  position: fixed;
  /* display: flex; */
  z-index: 4;
  /* justify-content: space-between; */
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

.KFCommunityTitle {
  height: 100%;
  float: left;
  padding-top: 1px;
  padding-left: 50px;
  padding-right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.KFViewTitle {
  height: 100%;
  float: left;
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
}

.KFUserName {
  height: 100%;
  float: right;
  padding-top: 11px;
  font-size: 10px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.LogoutButton {
  cursor: pointer;
  text-decoration: underline;
}

.main-style {
  clear: both;
  width: 100%;
  position: relative;
  top: 28px;
}
</style>
