<template>
  <div id="setting_window" class="iwindow aext" v-show="vIsDisplay" draggable="false" style="height: 300px;">
    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <div style="float:left;">
        Setting Tools
      </div>
      <div style="float:right;">
        <button class="iwindow-closebutton" v-on:click="closeWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="iwindow-body">
      My name is
      <span>{{ vFirstName }}</span>
      <span> </span>
      <span>{{ vLastName }}</span>
      <!-- <input v-model=""></input> -->
      <div v-show="vIsManager">
        I am a manager.<br>
        chatGPT API KEY:
        <input type="text" v-model="vAPIkey">
        <button @click="managerSetPressed">set</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */ //eslintのエラー制限
export default {
  name: "settingVue",
  data() {
    return {
      vIsDisplay: false,
      vIsManager: false,
      vFirstName: '',
      vLastName: '',
      vAPIkey: ''
    };
  },
  props: {
    gKFdata: null
  },
  async mounted() {
    //don't initialize here.
    //vue.js does not support mounting order for async / await.
  },
  methods: {
    openWindow: async function () {
      //initialize
      this.vIsManager = (this.gKFdata.user.role === 'manager');
      this.vFirstName = this.gKFdata.user.firstName;
      this.vLastName = this.gKFdata.user.lastName;

      var context = this.gKFdata.context;
      if (context.data && context.data.plugins && context.data.plugins.chotGPT) {
        this.vAPIkey = context.data.plugins.chotGPT.APIkey;
      }

      //var w = window.innerWidth;
      document.getElementById('setting_window').style.left = `100px`;
      document.getElementById('setting_window').style.top = `70px`;
      this.vIsDisplay = true;
    },
    closeWindow: function () {
      this.vIsDisplay = false;
    },
    async managerSetPressed() {
      //console.log(this.vAPIkey);
      var context = this.gKFdata.context;

      if (!context.data) {
        context.data = {};
      }
      if (!context.data.plugins) {
        context.data.plugins = {};
      }
      if (!context.data.plugins.chotGPT) {
        context.data.plugins.chotGPT = {};
      }
      context.data.plugins.chotGPT.APIkey = this.vAPIkey;

      await kf6.modifyObject(context);
    },
    mousedown: aextkf.windowmover
  }
};
</script>

<style scoped></style>