<template>
  <div class="login">
    <h1>login</h1>

    <form>
      <!-- <input type="text" v-model="login.aext_server" placeholder="login server" disabled /> -->
      <input type="text" v-model="login.aext_server" placeholder="login server" /><br />
      <input type="text" v-model="login.aext_uname" placeholder="user name" /><br />
      <input type="password" autocomplete="on" v-model="login.aext_password" placeholder="Password"
        v-on:keydown.enter="loginClicked" />
    </form>
    <button v-on:click="loginClicked">Login</button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "LoginView",
  data() {
    return {
      login: {
        aext_server: "https://kf6a.si.aoyama.ac.jp/",//
        aext_uname: "", //aext@gmail.com[テスト用]
        aext_password: "", //aext[テスト用]
      },
    };
  },
  props: {
    gKFdata: null
  },
  async mounted() {
    this.gKFdata.communityName = '';
    this.gKFdata.viewName = '';
    this.gKFdata.userName = '';
  },
  methods: {
    loginClicked: async function () {

      var serverURL = this.login.aext_server;
      kf6.setBaseURL(serverURL);
      var uname = this.login.aext_uname;
      var password = this.login.aext_password;

      var data = await kf6.login(uname, password);
      if (data.error) {
        alert("login failed: " + data.status);
        return;
      }
      window.sessionStorage.setItem("kfp.server", serverURL);
      window.sessionStorage.setItem("token", kf6.token);

      this.$router.push({ name: "community" }); //communityViewへ画面遷移する
    },
  },
};
</script>

<style scoped>
.login {
  padding: 10px;
}

input {
  width: 300px;
}
</style>