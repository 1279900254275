<template>
  <div id="search_window" class="iwindow aext" v-show="vIsDisplay" draggable="false" style="height: 300px;">
    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <!-- <div class="iwindow-titlebar" :ref="search" @mousedown="mousedown($event)"> -->
      <div style="float:left;">
        Search Tools
      </div>
      <div style="float:right;">
        <button class="iwindow-closebutton" v-on:click="closeWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="iwindow-body">
      <div>
        <input type="text" v-model="vSearchKey" placeholder="検索ワード" />
        <button @click="searchKeyPressed">検索</button>
      </div>
      <div style="height: 100%;">
        <button @click="frequencyKeyPressed">頻度</button>
        <div id="freq_list" style="height: 90%;overflow-y: scroll;">
          <table>
            <tr>
              <th>word</th>
              <th>freq</th>
            </tr>
            <tr v-for="word in this.vFreqList" v-bind:key="word.word">
              <td><a class="KFlink" @click="searchKeyPressed(word.word)">{{ word.word }}</a></td>
              <td>{{ word.count }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */ //eslintのエラー制限
export default {
  name: "searchVue",
  data() {
    return {
      vIsDisplay: false,
      vSearchKey: '',
      vFreqList: []
    };
  },
  props: {
    gKFdata: null
  },
  methods: {
    openWindow: async function () {
      var w = window.innerWidth;
      document.getElementById('search_window').style.left = `${w - 300}px`;
      document.getElementById('search_window').style.top = `70px`;
      this.vIsDisplay = true;
    },
    closeWindow: function () {
      this.clearHighlighting();
      this.vIsDisplay = false;
    },
    searchKeyPressed: async function (word) {
      this.clearHighlighting();

      if (typeof word === 'string') {
        this.vSearchKey = word;
      }

      var words = this.vSearchKey.split(' ');
      var query = {
        type: 'Note',
        viewIds: [this.gKFdata.currentView._id],
        pagesize: 1000,
        words: words
      };
      var objects = await kf6.getObjects(query);

      var linktable = {};
      for (var i = 0; i < this.gKFdata.linksOnView.length; i++) {
        var link = this.gKFdata.linksOnView[i];
        linktable[link.to] = link;
      }

      for (var i = 0; i < objects.length; i++) {
        var object = objects[i];
        var link = linktable[object._id];
        if (link) {
          link.isfound = true;
        }
      }
    },
    frequencyKeyPressed: async function () {
      var query = {
        type: 'Note',
        viewIds: [this.gKFdata.currentView._id],
        pagesize: 1000
      };
      var objects = await kf6.getObjects(query);
      var list = kftext.createWords(objects, 100);
      this.vFreqList = list;
    },
    clearHighlighting: function () {
      for (var i = 0; i < this.gKFdata.linksOnView.length; i++) {
        var link = this.gKFdata.linksOnView[i];
        link.isfound = false;
      }
    },
    mousedown: aextkf.windowmover
  }
};
</script>

<style scoped></style>