<template>
  <div id="search_window" class="iwindow aext" v-show="vIsDisplay" draggable="false">
    <div class="iwindow-titlebar" @mousedown="mousedown($event)">
      <div style="float:left;">
        Create View
      </div>
      <div style="float:right;">
        <button class="iwindow-closebutton" v-on:click="closeWindow">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="iwindow-body">
      <div class="createview-main">
        <div><input type="text" v-model="vNewName" placeholder="新規ビュー" /><button @click="addView">作成</button></div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ //eslintのエラー制限
export default {
  name: "createView",
  data() {
    return {
      vIsDisplay: false,
      vNewName: null,
    };
  },
  props: { gCurrentView: null },
  methods: {
    openWindow: async function () {
      this.vIsDisplay = true;
    },
    closeWindow: function () {
      //ノートを閉じるボタンが押されたら
      this.vIsDisplay = false;
      this.vNewName = null;
    },
    addView: async function () {
      if (this.vNewName === null) {
        return;
      }
      var view = await kf6.createView(this.gCurrentView, this.vNewName)
      var community = await kf6.getCommunity()
      community.views.push(view._id)
      await kf6.modifyCommunity(community)
      this.$emit("parent-event", view);
      this.closeWindow();
    },
    mousedown: aextkf.windowmover
  }
};
</script>
<style scoped>
.createview-main {
  background-color: white;
  top: 50px;
  left: 30px;
  padding: 30px;
  justify-content: center;
  align-items: center;
}
</style>